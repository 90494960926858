/* SectionThree.module.css */

.card {
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1),
        0 6px 20px rgba(0, 0, 0, 0.08),
        0 0 10px rgba(200, 200, 200, 0.3); */
    border-radius: 8px;
   padding: 5rem 0;
    /* margin: 20px; */
    margin-bottom: 3rem;
    transition: transform 0.3s, box-shadow 0.3s;
    /* background-color: #f9f9f9; */
    background-image: url("../../images/bg-shape1.png");
    /* Light grey background */
}





.title,
.text {
    white-space: normal;
    /* Ensure text wraps to the next line */
}

.title {
    font-size: 2.5rem;
    color: black;
    /* Increase title text size */
    margin-bottom: 15px;
    opacity: 0;
    /* Initially hidden */
}

.animateTitle {
    animation: slideUp 1s ease-out forwards;
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.text {
    text-align: center;
    /* Ensure the text is centered */
    font-size: 1.1rem;
    /* Increase text size */
    margin-bottom: 10px;
}

.button {
    background-color: orange !important;
    /* Button color without hover */
    border: none !important;
    font-size: 1.1rem;
    /* Increase button text size */
    padding: 10px 20px;
    border-radius-left: 50px;
}

.button:hover {
    background-color: #000 !important;
    /* Button color on hover */
}