/* CounterStats.module.css */

@import url('~@fortawesome/fontawesome-free/css/all.min.css');

/* Start count stats */

#counter-stats {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.container {
    /* max-width: 1200px; */
    padding: 0 15px;
    margin: 0 auto;
}

.stats {
    text-align: center;
    /* margin-bottom: 40px; */
}

.counting {
    font-size: 45px;
    color: #f96429;
    font-weight: 700;
    transition: color 0.3s ease-in-out;
}

.stats .icon {
    font-size: 32px;
    /* margin-bottom: 20px; */
    color: #555;
    transition: color 0.3s ease-in-out;
}

.statsItem:hover .counting,
.statsItem:hover .icon {
    color: #f78f26;
    /* Orange */
}

/* Different colors for each type of stat */
.code {
    color: #007bff;
    /* Blue */
}

.projects {
    color: #28a745;
    /* Green */
}

.clients {
    color: #dc3545;
    /* Red */
}

/* Adjust spacing between number and text */
h3 {
    margin-top: 10px;
    font-size: 24px;
    color: #444;
}

.statsItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out;
}

.statsItem:hover {
    background-color: #f8f9fa;
    /* Light gray */
}

/* Responsive styles */
@media (max-width: 768px) {
    .col-lg-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}