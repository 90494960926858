.text1{
    color:#f9bf29;
    font-size: 14px;
        font-weight: bold;
}


.noListStyle {
    list-style: none;
    padding-left: 0;
}

.listItem {
    /* display: flex;
    align-items: center; */
    margin-bottom: 15px;
}

.icon {
    margin-right: 10px;
    /* Space between the icon and the text */
color: #f9bf29;

    /* Color of the tick */
}


