/*
* Template Name: UntreeStore
* Template Author: Untree.co
* Author URI: https://untree.co/
* License: https://creativecommons.org/licenses/by/3.0/
*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
/* body {
  overflow-x: hidden;
  position: relative; } */
/* 
body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 28px;
  color: #6a6a6a;
  font-size: 14px;
  background-color: #eff2f1; } */
  /* background-image: linear-gradient(to right, #286fe7 0%, #1e51aa 100%); */
  :root {
    --main-color: #000000;
    --white-color: #ffffff;
    --black-color: #2B2540;
    --black-color-opacity: #2b2540c4;
    --paragraph-color: #818090;
    --bg-color: #F3F6FD;
    --transition: .4s all ease-in-out;
    /* --primary: #EB1616; */
  --secondary: #ffab00;
  --light: #fff;
  /* --dark: #01223b; */
  --dark: #000;
  --grey: #e6e6e673;
  /* --body: #e0e7ef; */
}


  html {
    scroll-behavior: smooth;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
  }
  /*----------------------------------------------------------------------------------------------------------------
    Global
  ----------------------------------------------------------------------------------------------------------------*/
  
  h1 {
    font-weight: 700;
  }
  h3, h4, h5, h6 {
    color: #000;
  }
  h2 {
    /*font-family: heading-extra-light;*/
    font-weight: 500;
  }
  p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
  }
  a {
    /*font-family: heading-bold;*/
    /*font-weight: 700;*/
    font-size: 16px;
    text-decoration: none;
  }
  a:hover{
    text-decoration: none;
  }
  li{
    list-style-type: none;
  }

  .mr0 {
    margin: 0;
  }
  .pd0 {
    padding: 0;
  }
  .section-entry {
    padding: 3.5rem 0 4.5rem;
  }
  .section-entry-top {
    padding-top: 4.5rem;
  }
  .section-entry-bottom {
    padding-bottom: 4.5rem;
  }
  .page-section {
    padding: 2rem 0;
  }
  
  .relative {
    position: relative;
  }
  .width100 {
    width: 100%;
  }
  .txtct {
    text-align: center;
  }
  .txtrt {
    text-align: right;
  }
  .txtupr {
    text-transform: uppercase;
  }
  .dflex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .align-c {
    align-items: center;
  }
  .txtbc h1, .txtbc h2, .txtbc h3, .txtbc h4, .txtbc h5, .txtbc h6, .txtbc p, .txtbc i, .txtbc a, .txtbc span {
    color: #000;
  }
  .txtwt h1, .txtwt h2, .txtwt h3, .txtwt h4, .txtwt h5, .txtwt h6, .txtwt p, .txtwt i, .txtwt a, .txtwt span {
    color: #fff;
  }
  .txtbu {
    color: #004c86;
  }
  .txtor {
    color: #ff2515;
  }
  .span-clr {
    color: var(--secondary);
  }
  


/* a {
  text-decoration: none;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  color: #2f2f2f;
  text-decoration: underline; }
  a:hover {
    color: #2f2f2f;
    text-decoration: none; }
  a.more {
    font-weight: 600; }

.custom-navbar {
  background: #3b5d50 !important;
  padding-top: 20px;
  padding-bottom: 20px; }
  .custom-navbar .navbar-brand {
    font-size: 32px;
    font-weight: 600; }
    .custom-navbar .navbar-brand > span {
      opacity: .4; }
  .custom-navbar .navbar-toggler {
    border-color: transparent; }
    .custom-navbar .navbar-toggler:active, .custom-navbar .navbar-toggler:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: none; }
  @media (min-width: 992px) {
    .custom-navbar .custom-navbar-nav li {
      margin-left: 15px;
      margin-right: 15px; } }
  .custom-navbar .custom-navbar-nav li a {
    font-weight: 500;
    color: #ffffff !important;
    opacity: .5;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    position: relative; }
    @media (min-width: 768px) {
      .custom-navbar .custom-navbar-nav li a:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 8px;
        right: 8px;
        background: #f9bf29;
        height: 5px;
        opacity: 1;
        visibility: visible;
        width: 0;
        -webkit-transition: .15s all ease-out;
        -o-transition: .15s all ease-out;
        transition: .15s all ease-out; } }
    .custom-navbar .custom-navbar-nav li a:hover {
      opacity: 1; }
      .custom-navbar .custom-navbar-nav li a:hover:before {
        width: calc(100% - 16px); }
  .custom-navbar .custom-navbar-nav li.active a {
    opacity: 1; }
    .custom-navbar .custom-navbar-nav li.active a:before {
      width: calc(100% - 16px); }
  .custom-navbar .custom-navbar-cta {
    margin-left: 0 !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    @media (min-width: 768px) {
      .custom-navbar .custom-navbar-cta {
        margin-left: 40px !important; } }
    .custom-navbar .custom-navbar-cta li {
      margin-left: 0px;
      margin-right: 0px; }
      .custom-navbar .custom-navbar-cta li:first-child {
        margin-right: 20px; } */


/* Navbar.....................................................................................................

......................................................................................................*/
/* Navbar Styles */
/* .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #333;
  padding: 1rem;
}

.navbar-brand {
  color: #fff;
  font-size: 1.5rem;
}

.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  position: relative;
  margin-right: 1rem;
}

.navbar-links a {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.navbar-links .dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background: #444;
  top: 100%;
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  border-bottom: 1px solid #555;
}

.dropdown-menu li a {
  padding: 0.5rem 1rem;
}

.dropdown-menu.show {
  display: block;
} */

/* Mobile Menu Styles */
/* .navbar-mobile-menu {
  display: none;
}

.navbar-mobile-menu button {
  background: #444;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.mobile-dropdown-menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-dropdown-menu li {
  border-bottom: 1px solid #555;
}

.mobile-dropdown-menu li a {
  display: block;
  padding: 0.5rem 1rem;
  color: #fff;
  text-decoration: none;
}

.mobile-dropdown-menu.show {
  display: block;
}

.mobile-dropdown .mobile-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.mobile-dropdown .mobile-dropdown-header a {
  flex-grow: 1;
}

.mobile-dropdown .mobile-dropdown-header button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.mobile-dropdown .mobile-submenu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  background: #555;
}

.mobile-dropdown .mobile-submenu.show {
  display: block;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }

  .navbar-mobile-menu {
    display: block;
  }
} */







/* Navbar Styles */
.navbar {
  /* display: flex; */
  /* justify-content: space-between;
  align-items: center; */
  /* background: #286fe7; */
  /* background-image: linear-gradient(to right, #000000 0%, #000000 100%); */
  background-color: #fff;
  padding: 0.5rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  transition: all 0.3s ease-in-out;
  
}

.navbar-brand {
  color: #000;
  font-size: 1.5rem;
}

.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  position: relative;
  margin-right: 1rem;
}

.navbar-links a {
  color: #000;
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 1rem;
}
.navbar-links a:hover {
  color: var(--secondary);
}

.navbar-links .dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  /* background: #36454f; */
  /* background-image: linear-gradient(to right, #36454f 0%, #36454f 100%); */
  /* background-color: var(--secondary); */
  top: 140%;
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  /* min-width: 200px;  */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dropdown-menu li {
  border-bottom: 1px solid #000;
  padding: 0.1rem 0 0.1rem 0;
}

.dropdown-menu li:last-child {
  border: none;
}
.dropdown-menu li a {
  padding: 0.5rem 1rem;
  white-space: nowrap; /* Ensure the text doesn't wrap */
  color: #000;
}

.dropdown-menu.show {
  display: block;
}

/* Mobile Menu Styles */
.navbar-mobile-menu {
  display: none;
}

.navbar-mobile-menu button {
  background: #444;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.mobile-dropdown-menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-dropdown-menu li {
  border-bottom: 1px solid #555;
}

.mobile-dropdown-menu li a {
  display: block;
  padding: 0.5rem 1rem;
  color: #fff;
  text-decoration: none;
}

.mobile-dropdown-menu.show {
  display: block;
}

.mobile-dropdown .mobile-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.mobile-dropdown .mobile-dropdown-header a {
  flex-grow: 1;
}

.mobile-dropdown .mobile-dropdown-header button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.mobile-dropdown .mobile-submenu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  background: #555;
}

.mobile-dropdown .mobile-submenu.show {
  display: block;
}


.navbar.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.spacer {
  height: 60px; /* Adjust this value to match the height of your navbar */
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }

  .navbar-mobile-menu {
    display: block;
  }
}





/*Navbar end ...................................... */

.logo {
  font-weight: 800;
  color: #000;
  font-size: 26px;
}
.my-btn {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--secondary);
  padding: 0.5rem 0.8rem;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}
.my-btn:hover {
  background-color: #000;
  color: #fff;
}
.nav-center {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}






/* 
---------------------------------------------
Services Style
--------------------------------------------- 
*/
.hm-ser-sm-title h6 {
  font-size: 13px;
}
.ser-title {
  font-size: 25px;
}
/* .hm-section {
  margin-top: 5rem;
} */

.services .service-item:hover .icon img {
  margin-top: -10px;
}

.services .service-item {
  position: relative;
  margin-top: 95px;
}

.services .service-item .icon {
  width: 160px;
  height: 160px;
  display: inline-block;
  text-align: center;
  line-height: 160px;
  background-color: var(--main-color);
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: -95px;
}

.services .service-item .icon img {
  max-width: 86px;
  transition: all .2s;
}

.services .service-item .main-content {
  border-radius: 25px;
  padding: 80px 30px 50px 30px;
  background-color: var(--grey);
  /* margin-bottom: 30px;
  margin-right: 80px; */
  transition: all .4s;
}

.services .service-item h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 30px;
  transition: all .4s;
}

.services .service-item p {
  color: #4a4a4a;
  margin-bottom: 25px;
}

.testimonial-slider {
  max-width: 1000px;
  margin: auto;
  padding: 20px;
}





.testimonial-slider {
  max-width: 1000px;
  margin: auto;
  padding: 20px;
}

.testimonial-item {
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; /* Ensure equal height */
}

.testimonial-item p {
  font-size: 1.1rem;
  font-style: italic;
  color: #555;
  margin-bottom: 10px;
}

.testimonial-item h5 {
  margin-top: auto;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.testimonial-item p:last-child {
  font-size: 1rem;
  color: #777;
}

.slick-dots {
  bottom: -30px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #000;
}

.slick-dots li.slick-active button:before {
  color: #000;
}








/* services code..................................................................... */
.banner-layout {
  background-image: url("../images/banner-layout1.jpg");
  background-size: cover;
  background-position: top right;
  padding: 7rem 0;
}

.banner-layout-about {
  background-image: url("../images/about-us.jpg");
  background-size: cover;
  background-position: top right;
  padding: 7rem 0;
}
.banner-layout-contact {
  background-image: url("../images/contact-banner.jpg");
  background-size: cover;
  background-position: top right;
  padding: 7rem 0;
}

/* .breadcrumb-item {
  color: #fff;
  font-weight: 700;
} */
.breadcrumb {
  justify-content: center;

}
.breadcrumb-item+.breadcrumb-item::before {
  color: var(--secondary);
}
.breadcrumb .breadcrumb-item a {
  color: #fff;
}
.breadcrumb-item.active {
  color: #fff;
  font-weight: 600;
}
.ser-li li {
 list-style: initial;
}
.ser-wel-row:nth-child(even) {
  flex-direction: row-reverse;
}

/* services code..................................................................... end */




/* about us code .............................................................. */
.counter-sec {
  background-image: url("../images/counter-bg.jpg");
  margin-bottom: 4rem;
  margin-top: 1.5rem;
}
/* about us code ..............................................................  end*/


/* services inside pages code ............................................................ */
.ser-wrap-inside {
  padding: 1.5rem;
  background-color: #fff;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
transition: 1s all ease-in-out;
  height: 100%;
}
.ser-wrap-inside:hover {
  background-color: var(--secondary);
}
.ser-wel {
  background-color: var(--grey);
}
.accordion-button:not(.collapsed) {
  background-color: var(--secondary);
  color: #000;
  font-weight: 500;
}
.accordion-button:focus {
  box-shadow: none;
}
.accordion-button {
  background-color: var(--grey);
  border: 1px solid #dee2e6;
}

/* services inside pages code ............................................................ end*/












.hero {
  background: #3b5d50;
  padding: calc(4rem - 30px) 0 0rem 0; }
  @media (min-width: 768px) {
    .hero {
      padding: calc(4rem - 30px) 0 4rem 0; } }
  @media (min-width: 992px) {
    .hero {
      padding: calc(8rem - 30px) 0 8rem 0; } }
  .hero .intro-excerpt {
    position: relative;
    z-index: 4; }
    @media (min-width: 992px) {
      .hero .intro-excerpt {
        max-width: 450px; } }
  .hero h1 {
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 30px; }
    @media (min-width: 1400px) {
      .hero h1 {
        font-size: 54px; } }
  .hero p {
    color: rgba(255, 255, 255, 0.5);
    margin-botom: 30px; }
  .hero .hero-img-wrap {
    position: relative; }
    .hero .hero-img-wrap img {
      position: relative;
      top: 0px;
      right: 0px;
      z-index: 2;
      max-width: 780px;
      left: -20px; }
      @media (min-width: 768px) {
        .hero .hero-img-wrap img {
          right: 0px;
          left: -100px; } }
      @media (min-width: 992px) {
        .hero .hero-img-wrap img {
          left: 0px;
          top: -80px;
          position: absolute;
          right: -50px; } }
      @media (min-width: 1200px) {
        .hero .hero-img-wrap img {
          left: 0px;
          top: -80px;
          right: -100px; } }
    .hero .hero-img-wrap:after {
      content: "";
      position: absolute;
      width: 255px;
      height: 217px;
      background-image: url("../images/dots-light.svg");
      background-size: contain;
      background-repeat: no-repeat;
      right: -100px;
      top: -0px; }
      @media (min-width: 1200px) {
        .hero .hero-img-wrap:after {
          top: -40px; } }

.btn {
  font-weight: 600;
  padding: 12px 30px;
  border-radius: 30px;
  color: #ffffff;
  background: #2f2f2f;
  border-color: #2f2f2f; }
  .btn:hover {
    color: #ffffff;
    background: #000;
    border-color: #000; }
  .btn:active, .btn:focus {
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .btn.btn-primary {
    background: #3b5d50;
    border-color: #3b5d50; }
    .btn.btn-primary:hover {
      background: #314d43;
      border-color: #314d43; }
  .btn.btn-secondary {
    color: #2f2f2f;
    background: #f9bf29;
    border-color: #f9bf29; }
    .btn.btn-secondary:hover {
      background: #f8b810;
      border-color: #f8b810; }
  .btn.btn-white-outline {
    background: transparent;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.3); }
    .btn.btn-white-outline:hover {
      border-color: white;
      color: #ffffff; }

.section-title {
  color: #2f2f2f; }

.product-section {
  padding: 7rem 0; }
  .product-section .product-item {
    text-align: center;
    text-decoration: none;
    display: block;
    position: relative;
    padding-bottom: 50px;
    cursor: pointer; }
    .product-section .product-item .product-thumbnail {
      margin-bottom: 30px;
      position: relative;
      top: 0;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease; }
    .product-section .product-item h3 {
      font-weight: 600;
      font-size: 16px; }
    .product-section .product-item strong {
      font-weight: 800 !important;
      font-size: 18px !important; }
    .product-section .product-item h3, .product-section .product-item strong {
      color: #2f2f2f;
      text-decoration: none; }
    .product-section .product-item .icon-cross {
      position: absolute;
      width: 35px;
      height: 35px;
      display: inline-block;
      background: #2f2f2f;
      bottom: 15px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      margin-bottom: -17.5px;
      border-radius: 50%;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease; }
      .product-section .product-item .icon-cross img {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    .product-section .product-item:before {
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      content: "";
      background: #dce5e4;
      height: 0%;
      z-index: -1;
      border-radius: 10px;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease; }
    .product-section .product-item:hover .product-thumbnail {
      top: -25px; }
    .product-section .product-item:hover .icon-cross {
      bottom: 0;
      opacity: 1;
      visibility: visible; }
    .product-section .product-item:hover:before {
      height: 70%; }

.why-choose-section {
  padding: 10.5rem 0 2rem; 
}
  .why-choose-section .img-wrap {
    position: relative; }
    .why-choose-section .img-wrap:before {
      position: absolute;
      content: "";
      width: 255px;
      height: 217px;
      background-image: url("../images/dots-yellow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      -webkit-transform: translate(-40%, -40%);
      -ms-transform: translate(-40%, -40%);
      transform: translate(-40%, -40%);
      z-index: -1; }
    .why-choose-section .img-wrap img {
      border-radius: 20px; }

.feature {
  margin-bottom: 30px; }
  .feature .icon {
    display: inline-block;
    position: relative;
    margin-bottom: 20px; }
    .feature .icon:before {
      content: "";
      width: 33px;
      height: 33px;
      position: absolute;
      background: rgba(59, 93, 80, 0.2);
      border-radius: 50%;
      right: -8px;
      bottom: 0; }
  .feature h3 {
    font-size: 14px;
    color: #2f2f2f; }
  .feature p {
    font-size: 14px;
    line-height: 22px;
    color: #6a6a6a; }

.we-help-section {
  padding: 7rem 0;
  background-color: var(--grey);
 }
  .we-help-section .imgs-grid {
    display: -ms-grid;
    display: grid;
    /* -ms-grid-columns: (1fr)[27]; */
    grid-template-columns: repeat(27, 1fr);
    position: relative; }
    .we-help-section .imgs-grid:before {
      position: absolute;
      content: "";
      width: 255px;
      height: 217px;
      background-image: url("../images/dots-green.svg");
      background-size: contain;
      background-repeat: no-repeat;
      -webkit-transform: translate(-40%, -40%);
      -ms-transform: translate(-40%, -40%);
      transform: translate(-40%, -40%);
      z-index: -1;
      filter: opacity(0.5);
     }
    .we-help-section .imgs-grid .grid {
      position: relative; }
      .we-help-section .imgs-grid .grid img {
        border-radius: 20px;
        max-width: 100%; }
      .we-help-section .imgs-grid .grid.grid-1 {
        -ms-grid-column: 1;
        -ms-grid-column-span: 18;
        grid-column: 1 / span 18;
        -ms-grid-row: 1;
        -ms-grid-row-span: 27;
        grid-row: 1 / span 27; }
      .we-help-section .imgs-grid .grid.grid-2 {
        -ms-grid-column: 19;
        -ms-grid-column-span: 27;
        grid-column: 19 / span 27;
        -ms-grid-row: 1;
        -ms-grid-row-span: 5;
        grid-row: 1 / span 5;
        padding-left: 20px; }
      .we-help-section .imgs-grid .grid.grid-3 {
        -ms-grid-column: 14;
        -ms-grid-column-span: 16;
        grid-column: 14 / span 16;
        -ms-grid-row: 6;
        -ms-grid-row-span: 27;
        grid-row: 6 / span 27;
        padding-top: 20px; }

.custom-list {
  width: 100%; }
  .custom-list li {
    display: inline-block;
    width: calc(50% - 20px);
    margin-bottom: 12px;
    line-height: 1.5;
    position: relative;
    padding-left: 20px; }
    .custom-list li:before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 2px solid #3b5d50;
      position: absolute;
      left: 0;
      top: 8px; }

.popular-product {
  padding: 0 0 7rem 0; }
  .popular-product .product-item-sm h3 {
    font-size: 14px;
    font-weight: 700;
    color: #2f2f2f; }
  .popular-product .product-item-sm a {
    text-decoration: none;
    color: #2f2f2f;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
    .popular-product .product-item-sm a:hover {
      color: rgba(47, 47, 47, 0.5); }
  .popular-product .product-item-sm p {
    line-height: 1.4;
    margin-bottom: 10px;
    font-size: 14px; }
  .popular-product .product-item-sm .thumbnail {
    margin-right: 10px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 120px;
    flex: 0 0 120px;
    position: relative; }
    .popular-product .product-item-sm .thumbnail:before {
      content: "";
      position: absolute;
      border-radius: 20px;
      background: #dce5e4;
      width: 98px;
      height: 98px;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: -1; }
/* 
.testimonial-section {
  padding: 3rem 0 7rem 0; }

.testimonial-slider-wrap {
  position: relative; }
  .testimonial-slider-wrap .tns-inner {
    padding-top: 30px; }
  .testimonial-slider-wrap .item .testimonial-block blockquote {
    font-size: 16px; }
    @media (min-width: 768px) {
      .testimonial-slider-wrap .item .testimonial-block blockquote {
        line-height: 32px;
        font-size: 18px; } }
  .testimonial-slider-wrap .item .testimonial-block .author-info .author-pic {
    margin-bottom: 20px; }
    .testimonial-slider-wrap .item .testimonial-block .author-info .author-pic img {
      max-width: 80px;
      border-radius: 50%; }
  .testimonial-slider-wrap .item .testimonial-block .author-info h3 {
    font-size: 14px;
    font-weight: 700;
    color: #2f2f2f;
    margin-bottom: 0; }
  .testimonial-slider-wrap #testimonial-nav {
    position: absolute;
    top: 50%;
    z-index: 99;
    width: 100%;
    display: none; }
    @media (min-width: 768px) {
      .testimonial-slider-wrap #testimonial-nav {
        display: block; } }
    .testimonial-slider-wrap #testimonial-nav > span {
      cursor: pointer;
      position: absolute;
      width: 58px;
      height: 58px;
      line-height: 58px;
      border-radius: 50%;
      background: rgba(59, 93, 80, 0.1);
      color: #2f2f2f;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease; }
      .testimonial-slider-wrap #testimonial-nav > span:hover {
        background: #3b5d50;
        color: #ffffff; }
    .testimonial-slider-wrap #testimonial-nav .prev {
      left: -10px; }
    .testimonial-slider-wrap #testimonial-nav .next {
      right: 0; }
  .testimonial-slider-wrap .tns-nav {
    position: absolute;
    bottom: -50px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
    .testimonial-slider-wrap .tns-nav button {
      background: none;
      border: none;
      display: inline-block;
      position: relative;
      width: 0 !important;
      height: 7px !important;
      margin: 2px; }
      .testimonial-slider-wrap .tns-nav button:active, .testimonial-slider-wrap .tns-nav button:focus, .testimonial-slider-wrap .tns-nav button:hover {
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        background: none; }
      .testimonial-slider-wrap .tns-nav button:before {
        display: block;
        width: 7px;
        height: 7px;
        left: 0;
        top: 0;
        position: absolute;
        content: "";
        border-radius: 50%;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease;
        background-color: #d6d6d6; }
      .testimonial-slider-wrap .tns-nav button:hover:before, .testimonial-slider-wrap .tns-nav button.tns-nav-active:before {
        background-color: #3b5d50; } */

.before-footer-section {
  padding: 7rem 0 12rem 0 !important; }

/* .blog-section {
  padding: 7rem 0 12rem 0; } */
  .blog-section {
    background-color: var(--grey);
    
  }
  .post-entry {
    background-color: #fff;
    padding: 1rem;
    height: 100%;
  }
  .blog-section .post-entry a {
    text-decoration: none; }
  .blog-section .post-entry .post-thumbnail {
    display: block;
    margin-bottom: 20px; }
    .blog-section .post-entry .post-thumbnail img {
      border-radius: 20px;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease; }
  .blog-section .post-entry .post-content-entry {
    padding-left: 15px;
    padding-right: 15px; }
    .blog-section .post-entry .post-content-entry h3 {
      font-size: 16px;
      margin-bottom: 0;
      font-weight: 600;
      margin-bottom: 7px; }
    .blog-section .post-entry .post-content-entry .meta {
      font-size: 14px; }
      .blog-section .post-entry .post-content-entry .meta a {
        font-weight: 600; }
  .blog-section .post-entry:hover .post-thumbnail img, .blog-section .post-entry:focus .post-thumbnail img {
    opacity: .7; }

.footer-section {
  /* padding: 80px 0 0; */
  background: var(--grey); 

}
  .footer-section .relative {
    position: relative; }
  .footer-section a {
    text-decoration: none;
    color: #2f2f2f;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
    .footer-section a:hover {
      color: rgba(47, 47, 47, 0.5); }
  .footer-section .subscription-form {
    margin-bottom: 40px;
    position: relative;
    z-index: 2;
    margin-top: 100px; }
    @media (min-width: 992px) {
      .footer-section .subscription-form {
        margin-top: 0px;
        margin-bottom: 80px; } }
    .footer-section .subscription-form h3 {
      font-size: 18px;
      font-weight: 500;
      color: #3b5d50; }
    .footer-section .subscription-form .form-control {
      height: 50px;
      border-radius: 10px;
      font-family: "Inter", sans-serif; }
      .footer-section .subscription-form .form-control:active, .footer-section .subscription-form .form-control:focus {
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-color: #3b5d50;
        -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2); }
      .footer-section .subscription-form .form-control::-webkit-input-placeholder {
        font-size: 14px; }
      .footer-section .subscription-form .form-control::-moz-placeholder {
        font-size: 14px; }
      .footer-section .subscription-form .form-control:-ms-input-placeholder {
        font-size: 14px; }
      .footer-section .subscription-form .form-control:-moz-placeholder {
        font-size: 14px; }
    .footer-section .subscription-form .btn {
      border-radius: 10px !important; }
  .footer-section .sofa-img {
    position: absolute;
    top: -200px;
    z-index: 1;
    right: 0; }
    .footer-section .sofa-img img {
      max-width: 380px; }
  .footer-section .links-wrap {
    margin-top: 0px; }
    @media (min-width: 992px) {
      .footer-section .links-wrap {
        margin-top: 54px; } }
    .footer-section .links-wrap ul li {
      margin-bottom: 10px; }
  .footer-section .footer-logo-wrap .footer-logo {
    font-size: 32px;
    font-weight: 500;
    text-decoration: none;
    color: #3b5d50; }
  .footer-section .custom-social li {
    margin: 2px;
    display: inline-block; }
    .footer-section .custom-social li a {
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      display: inline-block;
      background: #000;
      color: var(--secondary);
      border-radius: 50%; }
      .footer-section .custom-social li a:hover {
        background: #fff;
        color: #000; }
  .footer-section .border-top {
    border-color: #dce5e4; }
    .footer-section .border-top.copyright {
      font-size: 14px !important; }
/* 
.untree_co-section {
  padding: 7rem 0; } */

.form-control {
  height: 50px;
  border-radius: 10px;
  font-family: "Inter", sans-serif; }
  .form-control:active, .form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #3b5d50;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2); }
  .form-control::-webkit-input-placeholder {
    font-size: 14px; }
  .form-control::-moz-placeholder {
    font-size: 14px; }
  .form-control:-ms-input-placeholder {
    font-size: 14px; }
  .form-control:-moz-placeholder {
    font-size: 14px; }

.service {
  line-height: 1.5; }
  .service .service-icon {
    border-radius: 10px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: var(--secondary);
    margin-right: 20px;
    color: #000; }

textarea {
  height: auto !important; }

.site-blocks-table {
  overflow: auto; }
  .site-blocks-table .product-thumbnail {
    width: 200px; }
  .site-blocks-table .btn {
    padding: 2px 10px; }
  .site-blocks-table thead th {
    padding: 30px;
    text-align: center;
    border-width: 0px !important;
    vertical-align: middle;
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px; }
  .site-blocks-table td {
    padding: 20px;
    text-align: center;
    vertical-align: middle;
    color: rgba(0, 0, 0, 0.8); }
  .site-blocks-table tbody tr:first-child td {
    border-top: 1px solid #3b5d50 !important; }
  .site-blocks-table .btn {
    background: none !important;
    color: #000000;
    border: none;
    height: auto !important; }

.site-block-order-table th {
  border-top: none !important;
  border-bottom-width: 1px !important; }

.site-block-order-table td, .site-block-order-table th {
  color: #000000; }

.couponcode-wrap input {
  border-radius: 10px !important; }

.text-primary {
  color: #3b5d50 !important; }

.thankyou-icon {
  position: relative;
  color: #3b5d50; }
  .thankyou-icon:before {
    position: absolute;
    content: "";
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(59, 93, 80, 0.2); }
    
.list-unstyled {
  text-align: center;
  justify-content: center;
}
.copyright {
  padding: 0.3rem 0 0.1rem;
  border-top: 1px solid #000!important;
}

.ft-hover a:hover {
  color: var(--secondary);
}
  