


.noListStyle {
    list-style: none;
    padding-left: 0;
}

.listItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.icon {
    margin-right: 10px;
    /* Space between the icon and the text */
color: #f9bf29;
    /* Color of the tick */
}


